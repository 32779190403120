<script>
import { mapState } from 'vuex'

export default {
  name: 'VerticalMenuItem',

  components: {
    Icon: () => import('@/components/general/Icon')
  },

  props: {
    text: {
      type: String,
      default: ''
    },

    link: {
      type: [
        String,
        Object
      ],

      default: '#'
    },

    icon: {
      type: String
    },

    highlight: {
      type: Boolean,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },

    submenu: {
      type: Boolean,
      default: false
    },

    role: {
      type: String,
      default: null
    },

    exact: {
      type: Boolean,
      default: false
    },

    callback: {
      default: null
    }
  },

  data () {
    return {
      isActive: false,
      height: 0
    }
  },

  computed: {
    ...mapState([ 'Account' ]),
    isSameRoute () {
      return this.$route.name === this.link.name || this.$route.meta.parent === this.link.name
    }
  },

  mounted () {
    this.$nextTick(function () {
      if (this.submenu) {
        this.height = this.$refs.submenuList.clientHeight
        this.$refs.submenuList.style.height = 0
      }
    })
  },

  methods: {
    openSubmenu () {
      this.isActive = !this.isActive
      this.$refs.submenuList.style.height = this.isActive ? this.height + 'px' : 0
    },

    executeCallback () {
      if (this.callback) {
        this.callback()
      }
    }
  }
}
</script>

<template>
  <li
    v-if=" !disabled && !role || Account.user.currentProfile == role"
    class="menu-item"
    :class="{ 'is-submenu-active': isActive, 'has-submenu': submenu, 'is-highlight': highlight }"
  >
    <RouterLink
      v-if="!submenu"
      :to="link"
      class="menu-link"
      :exact="exact"
      :event="isSameRoute ? '' : 'click'"
      @click.native="executeCallback"
    >
      <Icon
        v-if="icon"
        :name="icon"
        wrapper
        size="medium"
      />

      <span class="text">{{ $t(text) }}</span>

      <Icon
        v-if="submenu"
        name="keyboard_arrow_down"
      />
    </RouterLink>

    <a
      v-else
      href="#"
      class="menu-link"
      @click.prevent="openSubmenu()"
    >
      <Icon
        v-if="icon"
        :name="icon"
        wrapper
        size="medium"
      />

      <span class="text">{{ $t(text) }}</span>

      <Icon
        v-if="submenu"
        name="keyboard_arrow_down"
      />
    </a>

    <ul
      v-if="submenu"
      ref="submenuList"
      class="submenu-list"
    >
      <slot />
    </ul>
  </li>
</template>
